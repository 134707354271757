<template>
    <v-card>
        <v-card-title class="text-h3 mb-2">Mot de passe</v-card-title>
        <v-card-subtitle>Modifier votre mot de passe pour plus de sécurité.</v-card-subtitle>
        <v-card-text>
            <v-btn block color="red" class="mb-4">Modifier mon mot de passe</v-btn>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name : 'Password',
}
</script>